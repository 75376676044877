import { SHARED_API, API_REPORT } from './endpoints'

export const API_URL = SHARED_API
export const API_URL_REPORT = API_REPORT
export const APPLICATION_NAME = 'CRM'
export const APPLICAITON_COPYRIGHT = '© 2022 Softlare GmbH. All rights reserved.'
export const VERSION = '2.0'
 
// export const SAVE_200_DESC = t("saveSuccessMessage")
// export const DEL_200_MSG = t("deleteSuccessTitle")
// export const DEL_200_DESC = t("deleteSuccessMessage")
// export const NETWORK_ERROR = t("networkError")
// export const NETWORK_ERROR_MESSAGE = t("networkErrorMessage")
// export const LOGGED_IN = t("loggedInTitle")
// export const LOGGED_IN_MESSAGE = t("loggedInDesc")
// export const LOGIN_FAILED = t("loginFailed")
// export const LOGIN_FAILED_MESSAGE = t("loginFailedMessage")

export const SAVE_200_MSG = "Erfolgreich"
export const SAVE_200_DESC = "Ihre Änderungen wurden gespeichert."

export const DEL_200_MSG = "Erfolgreich"
export const DEL_200_DESC = "Ihre Artikel wurde gelöscht."

export const NETWORK_ERROR = "Netzwerkfehler"
export const NETWORK_ERROR_MESSAGE = "Netzwerk ist ausgefallen!. API-Service sind nicht erreichbar. Wenden Sie sich an Ihren Administrator."

export const LOGGED_IN = "Eingeloggt"
export const LOGGED_IN_MESSAGE = "Sie haben sich erfolgreich bei Softlare CRM angemeldet"

export const LOGIN_FAILED = "Anmeldung fehlgeschlagen"
export const LOGIN_FAILED_MESSAGE = "Ihr Benutzername oder Passwort ist falsch."