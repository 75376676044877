import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import actions from './actions'
import { SAVE_200_MSG, SAVE_200_DESC, DEL_200_MSG, DEL_200_DESC } from '../../constants'
import { list, find, save, remove, createContract } from '../../services/contract'

function* fetchContractsRefresh() {
  const fe = JSON.parse(localStorage.getItem('fetchContracts'))
  if (fe == null) {
    fe.size = 10
    fe.index = 0
    fe.searchTerm = '%20'
    fe.orderBy = '%20'
  }
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(list, fe.size, fe.index, fe.searchTerm, fe.orderBy)
  yield put({
    type: actions.LIST_CONTRACT,
    result,
  })
}

function* fetchContracts({ size, index, searchTerm, orderBy }) {
  // Load last params froom last session.
  if (!size && !index && !searchTerm && !orderBy) {
    const fe = JSON.parse(localStorage.getItem('fetchContracts'))
    if (fe != null) {
      size = fe.size || 10
      index = fe.index || 0
      searchTerm = fe.searchTerm || '%20'
      orderBy = fe.orderBy || '%20'
    } else {
      size = 10
      index = 0
      searchTerm = '%20'
      orderBy = '%20'
    }
  }
  localStorage.setItem('fetchContracts', JSON.stringify({ size, index, searchTerm, orderBy }))
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(list, size, index, searchTerm, orderBy)
  yield put({
    type: actions.LIST_CONTRACT,
    result,
  })
}

function* fetchContractEdit({ id }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(find, id)
  yield put({
    type: actions.EDIT_CONTRACT,
    result,
  })
}

function* fetchContractSave(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(save, values)
  if (result !== null) {
    notification.success({ message: SAVE_200_MSG, description: SAVE_200_DESC })
  }
  yield put({
    type: actions.SAVE_CONTRACT_RECIEVED,
    result,
  })
  yield put({
    type: actions.REQUESTING,
  })
  const result2 = yield call(find, result.contractID)
  yield put({
    type: actions.EDIT_CONTRACT,
    result: result2,
  })
  // yield put({
  //   type: actions.RE_FETCH_CONTRACTS,
  // })
}

// function* fetchContractSave(values, silent) {
//   yield put({
//     type: actions.REQUESTING,
//   })
//   const result = yield call(save, values)
//   if (result !== null && silent === false) {
//     notification.success({ message: SAVE_200_MSG, description: SAVE_200_DESC })
//   }
//   yield put({
//     type: actions.EDIT_CONTRACT,
//     result,
//   });
// }

function* fetchContractDelete(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(remove, values)
  if (result !== null) {
    notification.success({ message: DEL_200_MSG, description: DEL_200_DESC })
  }
  yield put({
    type: actions.DELETE_CONTRACT_RECIEVED,
    result,
  })
  yield put({
    type: actions.RE_FETCH_CONTRACTS,
  })
}

function* createContrac2t({ id }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result2 = yield call(createContract, id)
  yield put({
    type: actions.CREATE_CONTRACT_RECIEVED,
    result2,
  })
  const result = yield call(find, result2.contractID)
  yield put({
    type: actions.EDIT_CONTRACT,
    result,
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.CREATE_CONTRACT, createContrac2t),
    takeEvery(actions.FETCH_CONTRACTS, fetchContracts),
    takeEvery(actions.FETCH_CONTRACT, fetchContractEdit),
    takeEvery(actions.SAVE_CONTRACT, fetchContractSave),
    takeEvery(actions.DELETE_CONTRACT, fetchContractDelete),
    takeEvery(actions.RE_FETCH_CONTRACTS, fetchContractsRefresh),
  ])
}
