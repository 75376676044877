import React from 'react'
import { connect } from 'react-redux'
import { Layout } from 'antd'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'
// import Sidebar from '@airui/layout/Sidebar'
// import SupportChat from '@airui/layout/SupportChat'
import { APPLICATION_NAME , APPLICAITON_COPYRIGHT } from 'constants/index'
import style from './style.module.scss' 

const mapStateToProps = ({ settings }) => ({
  isGrayTopbar: settings.isGrayTopbar,
  isCardShadow: settings.isCardShadow,
  isSquaredBorders: settings.isSquaredBorders,
  isBorderless: settings.isBorderless,
  authPagesColor: settings.authPagesColor,
  logo: settings.logo,
  description: settings.description,
})

const AuthLayout = ({
  children,
  isCardShadow,
  isSquaredBorders,
  isBorderless,
  authPagesColor,
}) => {
  return (
    <Layout>
      <Layout.Content>
        {/* <Sidebar /> */}
        {/* <SupportChat /> */}
        <div
          className={classNames(`${style.container}`, {
            air__layout__squaredBorders: isSquaredBorders,
            air__layout__cardsShadow: isCardShadow,
            air__layout__borderless: isBorderless,
            [style.white]: authPagesColor === 'white',
            [style.gray]: authPagesColor === 'gray',
          })}
          style={{
            backgroundImage:
              authPagesColor === 'image' ? 'url(resources/images/content/photos/8.jpeg)' : '',
          }}
        >
          <div className="pt-5 pb-5 d-flex align-items-end mt-auto">
            <div style={{ alignItems: 'center', flexDirection: 'column', display: 'flex' }}>
              <div style={{ marginBottom: 0 , alignItems: 'center', flexDirection: 'column', display: 'flex'}}>
                <img
                  style={{ width: '50%', height: 'auto' }}
                  src="/resources/images/softlare-logo.svg"
                  alt="Softlare Logo"
                />
              </div>
              <div className="air__utils__logo__text" style={{ marginLeft: 0, marginTop: 12 }}>
                <div className="air__utils__logo__descr text-uppercase font-size-12 text-gray-6 text-center">
                  {APPLICATION_NAME}
                </div>
              </div>
            </div>
          </div>
          <div className={style.containerInner}>{children}</div>
          <div className="mt-auto pb-5 pt-5">
            {/* <ul
              className={`${style.footerNav} list-unstyled d-flex mb-0 flex-wrap justify-content-center`}
            >
              <li>
                <a href="#" onClick={e => e.preventDefault()}>
                  Terms of Use
                </a>
              </li>
              <li>
                <a href="#" onClick={e => e.preventDefault()}>
                  Compliance
                </a>
              </li>
              <li>
                <a href="#" onClick={e => e.preventDefault()}>
                  Support
                </a>
              </li>
              <li>
                <a href="#" onClick={e => e.preventDefault()}>
                  Contacts
                </a>
              </li>
            </ul> */}
            <div className="text-center">
              {APPLICAITON_COPYRIGHT} <br />
              <a href="https://www.mediatec.org/privacy" target="_blank" rel="noopener noreferrer">
                Privacy Policy
              </a>
            </div>
          </div>
        </div>
      </Layout.Content>
    </Layout>
  )
}

export default withRouter(connect(mapStateToProps)(AuthLayout))
