import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import actions from './actions'
import { SAVE_200_MSG, SAVE_200_DESC, DEL_200_MSG, DEL_200_DESC } from '../../constants'
import { find, save, remove, getFilterResults, getFilterResults2,getFilterResults23 } from '../../services/callCenterActivityLog'

function* fetchCallCenterActivityLogEdit({ id }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(find, id)
  yield put({
    type: actions.EDIT_CALLCENTERACTIVITYLOG,
    result,
  })
}

function* fetchCallCenterActivityLogSave(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(save, values)
  if (result !== null) {
    notification.success({ message: SAVE_200_MSG, description: SAVE_200_DESC })
  }
  yield put({
    type: actions.SAVE_CALLCENTERACTIVITYLOG_RECIEVED,
    result,
  })
  yield put({
    type: actions.RE_FETCH_CALLCENTERACTIVITYLOGS,
  })
}

function* fetchCallCenterActivityLogDelete(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(remove, values)
  if (result !== null) {
    notification.success({ message: DEL_200_MSG, description: DEL_200_DESC })
  }
  yield put({
    type: actions.DELETE_CALLCENTERACTIVITYLOG_RECIEVED,
    result,
  })
  yield put({
    type: actions.RE_FETCH_CALLCENTERACTIVITYLOGS,
  })
}

function* fetchFilterResults({
  size,
  index,
  searchTerm,
  orderBy,
  user,
  date,
  message,
  companyID
}) {
  yield put({ type: 'RESET_COMPANY_EDIT' })
  const storageName = "callcenterlogs";
  const ts = localStorage.getItem(storageName)
  if (ts !== undefined && ts != null) {
    try {
      const fe = JSON.parse(ts)
      if (fe != null) {
        if (size === undefined) size = fe.size || size
        if (index === undefined) index = fe.index || index
        if (searchTerm === undefined) searchTerm = fe.searchTerm || searchTerm
        if (orderBy === undefined) orderBy = fe.orderBy || orderBy
        user = fe.user || user
        date = fe.date || date
        message = fe.message || message
        companyID = fe.companyID || companyID
      }
    } catch (e) {
      console.log(e)
    }
  }
  size = size || localStorage.getItem('pageSize') || 15
  try {
    localStorage.setItem(storageName, JSON.stringify({
      size,
      index,
      searchTerm,
      orderBy,
      user,
      date,
      message,
      companyID
    }),
    )
  } catch (e) {
    console.log(e)
  }
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(
    getFilterResults,
    size,
    index,
    searchTerm,
    orderBy,
    user,
    date,
    message,
    companyID
  )
  yield put({
    type: actions.LIST_CALLCENTERACTIVITYLOG,
    result,
  })
}

function* fetchFilterResults2({
  size,
  index,
  searchTerm,
  orderBy,
  user,
  date,
  message,
  companyID
}) {
  yield put({ type: 'RESET_COMPANY_EDIT' })
  const storageName = "callcenterlogs88";
  const ts = localStorage.getItem(storageName)
  if (ts !== undefined && ts != null) {
    try {
      const fe = JSON.parse(ts)
      if (fe != null) {
        if (size === undefined) size = fe.size || size
        if (index === undefined) index = fe.index || index
        if (searchTerm === undefined) searchTerm = fe.searchTerm || searchTerm
        if (orderBy === undefined) orderBy = fe.orderBy || orderBy
        user = fe.user || user
        date = fe.date || date
        message = fe.message || message
        companyID = fe.companyID || companyID
      }
    } catch (e) {
      console.log(e)
    }
  }
  size = size || localStorage.getItem('pageSize') || 15
  try {
    localStorage.setItem(storageName, JSON.stringify({
      size,
      index,
      searchTerm,
      orderBy,
      user,
      date,
      message,
      companyID
    }),
    )
  } catch (e) {
    console.log(e)
  }
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(
    getFilterResults2,
    size,
    index,
    searchTerm,
    orderBy,
    user,
    date,
    message,
    companyID
  )
  yield put({
    type: actions.LIST_CALLCENTERACTIVITYLOG2,
    result,
  })
}

function* fetchFilterResults23({
  size,
  index,
  searchTerm,
  orderBy,
}) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(
    getFilterResults23,
    size,
    index,
    searchTerm,
    orderBy,
  )
  yield put({
    type: actions.LIST_CALLCENTERACTIVITYLOG23,
    result,
  })
}
function* FETCH_CALLCENTERACTIVITYLOGS23_OPEN() {
  yield put({
    type: actions.FETCH_CALLCENTERACTIVITYLOGS23_OPEN_RECIEVED,
  })
}
function* FETCH_CALLCENTERACTIVITYLOGS23_CLOSE() {
  yield put({
    type: actions.FETCH_CALLCENTERACTIVITYLOGS23_CLOSE_RECIEVED,
  })
}

export default function* rootSaga() {
  yield all([

    takeEvery(actions.FETCH_CALLCENTERACTIVITYLOGS23_OPEN, FETCH_CALLCENTERACTIVITYLOGS23_OPEN),
    takeEvery(actions.FETCH_CALLCENTERACTIVITYLOGS23_CLOSE, FETCH_CALLCENTERACTIVITYLOGS23_CLOSE),

    takeEvery(actions.FETCH_CALLCENTERACTIVITYLOGS23, fetchFilterResults23),
    takeEvery(actions.FETCH_CALLCENTERACTIVITYLOGS2, fetchFilterResults2),
    takeEvery(actions.FETCH_CALLCENTERACTIVITYLOGS, fetchFilterResults),
    takeEvery(actions.FETCH_CALLCENTERACTIVITYLOG, fetchCallCenterActivityLogEdit),
    takeEvery(actions.SAVE_CALLCENTERACTIVITYLOG, fetchCallCenterActivityLogSave),
    takeEvery(actions.DELETE_CALLCENTERACTIVITYLOG, fetchCallCenterActivityLogDelete),
    takeEvery(actions.RE_FETCH_CALLCENTERACTIVITYLOGS, fetchFilterResults),
  ])
}