const actions = {
  REQUESTING: 'Userss/REQUESTING',
  RESPONSE_ERROR: 'Userss/RESPONSE_ERROR',

  FETCH_USERSS: 'Userss/FETCH',
  FETCH_USERSS2: 'Userss/FETCH2',
  LIST_USERS: 'Users/LIST',

  FETCH_USERS: 'Users/FETCH',
  NEW_USERS: 'Users/NEW',
  EDIT_USERS: 'Users/EDIT',
  RESET_USERS: 'Users/RESET',

  RE_FETCH_USERSS: 'Userss/REFRESH',
  SAVE_USERS: 'Users/SAVE',
  SAVE_USERS_RECIEVED: 'Users/SAVE_USERS_RECIEVED',

  DELETE_USERS: 'Users/DELETE',
  DELETE_USERS_RECIEVED: 'Users/DELETE_RECIEVED',

  FETCH_PROFILE: 'Users/Profile',
  FETCH_PROFILE_SUCCESS: 'Users/Profile/Edit',
  FETCH_PROFILE_CLOSE: 'Users/Profile/Edit',
}

export default actions
