import actions from './actions'

export default function CompanyIGZsReducer(state = { loading: false }, action) {
  switch (action.type) {
    case actions.REQUESTING:
      return {
        ...state,
        loading: true,
        editing: null,
        saveSuccess: false,
        data: null,
        calls: null,
        totalCounts: null,
        whatthinks: null,  
        emailParameters: null,
        openEmail: false,
        emailSendResult: false, 
      }
    case actions.LIST_DASHBOARD:
      return {
        ...state,
        data: action.result,
        loading: false,
      }
    case actions.FETCH_CHART_FOR_CALL_RECIEVED:
      return {
        ...state,
        calls: action.result,
        loading: false,
      }
    case actions.FETCH_TOTAL_COUNTS_RECIEVED:
      return {
        ...state,
        totalCounts: action.result,
        loading: false,
      }
    case actions.FETCH_TIMES_RECIEVED:
      return {
        ...state,
        times: action.result,
        loading: false,
      }
    case actions.FETCH_TIMESHEET_RECIEVED:
      return {
        ...state,
        timeSheet: action.result,
        loading: false,
      }
    case actions.FETCH_TIMES_CALL_ACITITY_RECIEVED:
      return {
        ...state,
        times2: action.result,
        loading: false,
      }
    case actions.UPDATE_DURATIONS_RECIEVED:
      return {
        ...state,
        durations: action.result,
        loading: false,
      }
    case actions.FETCH_RECALL_LIST_RECIEVED:
      return {
        ...state,
        recalls: action.result,
        loading: false,
      }
    case actions.FETCH_REMINDER_LIST_RECIEVED:
      return {
        ...state,
        reminders: action.result,
        loading: false,
      }
    case actions.UPDATE_TIMESHEET_UODATE_RECIEVED:
      return {
        ...state,
        loading: false,
      }

    case actions.FETCH_WHAT_THINKS_RECIEVED:
      return {
        ...state,
        whatthinks: {
          "labels": ["Interested", "Not interested", "Undecided", "Sold"],
          "datasets": [
            {
              "data": action.result,
              "backgroundColor": ["#ffc107", "#343a40", "#007bff", "#28a745"],
              "borderColor": "#fff",
              "borderWidth": 2,
              "hoverBorderWidth": 0,
              "borderAlign": "inner"
            }
          ]
        },
        loading: false,
        saveSuccess: false
      }


    case actions.FETCH_ACTIVITY_TIMES_RECIEVED:
      return {
        ...state,
        activityTimes: action.result,
        loading: false,
      }

    case actions.FETCH_CALL_TIMES_RECIEVED:
      return {
        ...state,
        callTimes: action.result,
        loading: false,
      }

    case actions.FETCH_CALL_AND_ACTIVITY_TIMES_RECIEVED:
      return {
        ...state,
        activityAndCallTimes: action.result,
        loading: false,
      }

    case actions.FETCH_timesheetsAgain_recieved:
      return {
        ...state,
        loading: false,
      }


    case actions.RESET_DASHBOARD:
      return {
        ...state,
        editing: null,
        data: null,
        calls: null,
        totalCounts: null,
        loading: false,
        saveSuccess: false
      }
    case actions.RESPONSE_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        saveSuccess: false,
        message: action.message
      }

    case actions.CLOSE_SENDEMAIL_MODAL_RECIEVED:
      return {
        ...state,
        openEmail: false,
        loading: false,
      }

    case actions.OPEN_SENDEMAIL_MODAL_RECIEVED:
      return {
        ...state,
        openEmail: true,
        loading: false,
      }

    case actions.LOAD_SENDEMAIL_PARAMETERS_RECIEVED:
      return {
        ...state,
        openEmail: true,
        emailParameters: action.result,
        emailSendResult: false,
        loading: false,
      }

    case actions.SENDEMAIL_TIMESHEET_RECIEVED:
      return {
        ...state,
        emailSendResult: true, 
        loading: false,
      }

 

    default:
      return state
  }
}
