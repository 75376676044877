import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import actions from './actions'
import { SAVE_200_MSG, SAVE_200_DESC, DEL_200_MSG, DEL_200_DESC } from '../../constants'
import { list, find, save, remove, getall, saveall, save2 } from '../../services/companyManager'

function* fetchCompanyManagersRefresh() {
  const fe = JSON.parse(localStorage.getItem('fetchCompanyManagers'))
  if (fe == null) {
    fe.size = 10
    fe.index = 0
    fe.searchTerm = '%20'
    fe.orderBy = '%20'
  }
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(list, fe.size, fe.index, fe.searchTerm, fe.orderBy)
  yield put({
    type: actions.LIST_COMPANYMANAGER,
    result,
  })
}

function* fetchCompanyManagers({ size, index, searchTerm, orderBy }) {
  // Load last params froom last session.
  if (!size && !index && !searchTerm && !orderBy) {
    const fe = JSON.parse(localStorage.getItem('fetchCompanyManagers'))
    if (fe != null) {
      size = fe.size || 10
      index = fe.index || 0
      searchTerm = fe.searchTerm || '%20'
      orderBy = fe.orderBy || '%20'
    } else {
      size = 10
      index = 0
      searchTerm = '%20'
      orderBy = '%20'
    }
  }
  localStorage.setItem('fetchCompanyManagers', JSON.stringify({ size, index, searchTerm, orderBy }))
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(list, size, index, searchTerm, orderBy)
  yield put({
    type: actions.LIST_COMPANYMANAGER,
    result,
  })
}

function* fetchCompanyManagerEdit({ id }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(find, id)
  yield put({
    type: actions.EDIT_COMPANYMANAGER,
    result,
  })
}

function* fetchCompanyManagerSave(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(save, values)
  if (result !== null) {
    notification.success({ message: SAVE_200_MSG, description: SAVE_200_DESC })
  }
  yield put({
    type: actions.SAVE_COMPANYMANAGER_RECIEVED,
    result,
  })
  yield put({
    type: actions.RE_FETCH_COMPANYMANAGERS,
  })
}

function* fetchCompanyManagerDelete(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(remove, values)
  if (result !== null) {
    notification.success({ message: DEL_200_MSG, description: DEL_200_DESC })
  }
  yield put({
    type: actions.DELETE_COMPANYMANAGER_RECIEVED,
    result,
  })
  yield put({
    type: actions.RE_FETCH_COMPANYMANAGERS,
  })
}

function* saveall2(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(saveall, values)
  if (result !== null) {
    notification.success({ message: SAVE_200_MSG, description: SAVE_200_DESC })
  }
  yield put({
    type: actions.GETALL_COMPANYMANAGERS,
  })
}

function* getAllManagers() {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(getall)
  yield put({
    type: actions.GETALL_COMPANYMANAGERS_RECIEVED,
    result,
  })
}
function* resetAll() {
  yield put({
    type: actions.RESET_COMPANYMANAGERS2,
  }) 
}

function* fetchCompanyManagerSave2(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(save2, values)
  if (result !== null) {
    notification.success({ message: SAVE_200_MSG, description: SAVE_200_DESC })
  }
  yield put({
    type: actions.SAVE_COMPANYMANAGER_RECIEVED2,
    values
  }); 
  // let size = 10;
  // let index=  0;
  // const fe = JSON.parse(localStorage.getItem('update_list'))
  // if (fe != null) {
  //   size = fe.size || 100
  //   index = fe.index || 0
  // } else {
  //   size = 100
  //   index = 0
  // }
  // localStorage.setItem('update_list', JSON.stringify({ size, index }))
  // yield put({
  //   type: actions.REQUESTING,
  // })
  // const result2 = yield call(getNotFilledManagerCompanys, size, index);
  // yield put({
  //   type: 'getNotFilledManagerCompanys_recieved',
  //   result: result2,
  // })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.SAVE_COMPANYMANAGER2, fetchCompanyManagerSave2),

    takeEvery(actions.SAVE_COMPANYMANAGER_ALL, saveall2),
    takeEvery(actions.GETALL_COMPANYMANAGERS, getAllManagers),

    takeEvery(actions.RESET_COMPANYMANAGERS, resetAll),

    takeEvery(actions.FETCH_COMPANYMANAGERS, fetchCompanyManagers),
    takeEvery(actions.FETCH_COMPANYMANAGER, fetchCompanyManagerEdit),
    takeEvery(actions.SAVE_COMPANYMANAGER, fetchCompanyManagerSave),
    takeEvery(actions.DELETE_COMPANYMANAGER, fetchCompanyManagerDelete),
    takeEvery(actions.RE_FETCH_COMPANYMANAGERS, fetchCompanyManagersRefresh),
  ])
}
