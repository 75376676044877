import { getRequest, postRequest, logRequest } from './api'

export async function list(size, index, searchTerm, orderBy) {
  logRequest("Show Travel List")
  return getRequest(`travel/get-list/${size || 10}/${index || 0}/${searchTerm || '%20'}/${orderBy || '%20'}`)
}

export async function find(id) {
  logRequest("Show Travel", JSON.stringify(id))
  return getRequest(`travel/edit/${id}`)
}

export async function save(values) {
  logRequest("Save Travel")
  return postRequest(`travel/save`, values)
}

export function remove(values) {
  logRequest("Delete Travel", JSON.stringify(values.values))
  return postRequest(`travel/delete`, values)
}
