import { getRequest, postRequest } from './api'

export async function list(size, index, searchTerm, orderBy) {
  return getRequest(
    `mouseActivity/get-list/${size || 10}/${index || 0}/${searchTerm || '%20'}/${orderBy || '%20'}`,
  )
}

export async function find(id) {
  return getRequest(`mouseActivity/edit/${id}`)
}

export async function save(values) {
  return postRequest(`mouseActivity/save`, values)
}

export function remove(values) {
  return postRequest(`mouseActivity/delete`, values)
}
