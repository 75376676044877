import { getRequest, postRequest } from './api'

export async function list(size, index, searchTerm, orderBy) {
  return getRequest(`provision/get-list/${size || 10}/${index || 0}/${searchTerm || '%20'}/${orderBy || '%20'}`)
}

export async function list2(size, index, searchTerm, orderBy, month, year, user) {
  return getRequest(`provision/get-list2/${size || 10}/${index || 0}/${'%20'}/${orderBy || '%20'}/${month || '%20'}/${year || '%20'}/${user || '%20'}`)
}

export async function find(id) {
  return getRequest(`provision/edit/${id}`)
}

export async function save(values) {
  return postRequest(`provision/save`, values)
}

export function remove(values) {
  return postRequest(`provision/delete`, values)
}
