import actions from './actions'

export default function UserssReducer(state = { loading: false }, action) {
  switch (action.type) {
    case actions.REQUESTING:
      return {
        ...state,
        loading: true,
        editing: null,
      }
    case actions.LIST_USERS:
      return {
        ...state,
        result: action.result,
        loading: false,
      }

    case actions.EDIT_USERS:
      return {
        ...state,
        editing: action.result,
        loading: false,
      }
    case actions.FETCH_PROFILE_SUCCESS:
      return {
        ...state,
        profile: action.result,
        loading: false,
      }
    case actions.FETCH_PROFILE_CLOSE:
      return {
        ...state,
        profile: null,
        loading: false,
      }
    case actions.RESET_USERS:
      return {
        ...state,
        editing: null,
        profile: null,
        loading: false,
        saveSuccess: false,
      }
    case actions.NEW_USERS:
      return {
        ...state,
        editing: { userID: 0 },
        loading: false,
        saveSuccess: false,
      }
    case actions.CREATE_USERS_RECIEVED ||
      actions.UPDATE_USERS_RECIEVED ||
      actions.DELETE_USERS_RECIEVED:
      return {
        ...state,
        editing: { userID: 0 },
        loading: false,
        saveSuccess: true, // mark
      }
    case actions.RESPONSE_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        saveSuccess: false,
        message: action.message,
      }
    default:
      return state
  }
}
