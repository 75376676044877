const actions = {
  LOAD_CURRENT_ACCOUNT: 'user/LOAD_CURRENT_ACCOUNT',
  SET_STATE: 'user/SET_STATE',
  LOGOUT: 'user/LOGOUT',
  LOGIN: 'user/LOGIN',
  TIMEOUT: 'user/TIMEOUT',
  TIMEOUT_RECIEVED: 'user/TIMEOUT_RECIEVED',
}

export default actions
