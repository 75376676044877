import { getRequest, postRequest, logRequest } from './api'

export async function list(size, index, searchTerm, orderBy) {
  logRequest("Show Personal List")
  return getRequest(`personal/get-list/${size || 10}/${index || 0}/${searchTerm || '%20'}/${orderBy || '%20'}`)
}

export async function find(id) {
  logRequest("Show Personal", JSON.stringify(id))
  return getRequest(`personal/edit/${id}`)
}

export async function save(values) {
  logRequest("Save Personal")
  return postRequest(`personal/save`, values)
}

export function remove(values) {
  logRequest("Delete Personal", JSON.stringify(values.values))
  return postRequest(`personal/delete`, values)
}
 