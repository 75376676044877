import React from 'react'
import { useTranslation } from 'react-i18next';

const Status = () => {
  const { t } = useTranslation();
  return (
    <div>
      {t('topBar.status')}
      <span className="ml-2 p-1 badge bg-danger text-white font-size-12 text-uppercase">
        Trialing
      </span>
    </div>
  )
}

export default Status
