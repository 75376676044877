import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import actions from './actions'
import { SAVE_200_MSG, SAVE_200_DESC, DEL_200_MSG, DEL_200_DESC } from '../../constants'
import { list, find, save, remove } from '../../services/userEvent'

function* fetchUserEvents({ size, index, searchTerm, orderBy }) {
  if (!size && !index && !searchTerm && !orderBy) {
    size = 10
    index = 0
    searchTerm = '%20'
    orderBy = '%20'
  }
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(list, size, index, searchTerm, orderBy)
  yield put({
    type: actions.LIST_USEREVENT,
    result,
  })
}

function* fetchUserEventEdit({ id, start, end, userId }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(find, id)
  yield put({
    type: actions.EDIT_USEREVENT,
    result,
    start,
    end,
    userId
  })
}

function* fetchUserEventSave(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(save, values)
  if (result !== null) {
    notification.success({ message: SAVE_200_MSG, description: SAVE_200_DESC })
  }
  yield put({
    type: actions.SAVE_USEREVENT_RECIEVED,
    result,
  })
  yield put({
    type: actions.RE_FETCH_USEREVENTS,
  })
}

function* fetchUserEventDelete(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(remove, values)
  if (result !== null) {
    notification.success({ message: DEL_200_MSG, description: DEL_200_DESC })
  }
  yield put({
    type: actions.DELETE_USEREVENT_RECIEVED,
    result,
  })
  yield put({
    type: actions.RE_FETCH_USEREVENTS,
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_USEREVENTS, fetchUserEvents),
    takeEvery(actions.FETCH_USEREVENT, fetchUserEventEdit),

    takeEvery(actions.SAVE_USEREVENT, fetchUserEventSave),
    takeEvery(actions.DELETE_USEREVENT, fetchUserEventDelete),
    takeEvery(actions.RE_FETCH_USEREVENTS, fetchUserEvents),
  ])
}
