import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import actions from './actions'
import { SAVE_200_MSG, SAVE_200_DESC, DEL_200_MSG, DEL_200_DESC } from '../../constants'
import { list2, find, save, remove } from '../../services/provision'
 

function* fetchProvisions({ size, index, searchTerm, orderBy, month, year, user }) {
  // if (!size && !index && !searchTerm && !orderBy) {
  //   const fe = JSON.parse(localStorage.getItem('fetchProvisions'))
  //   if (fe != null) {
  //     size = fe.size || 10
  //     index = fe.index || 0
  //     searchTerm = fe.searchTerm || '%20'
  //     orderBy = fe.orderBy || '%20'
  //     month = fe.month || '%20'
  //     year = fe.year || '%20'
  //     user = fe.user || '%20'
  //   } else {
  //     size = 10
  //     index = 0
  //     searchTerm = '%20'
  //     orderBy = '%20'
  //     month = '%20'
  //     year = '%20'
  //     user = '%20'
  //   }
  // }
  localStorage.setItem('fetchProvisions', JSON.stringify({ size, index, searchTerm, orderBy, month, year, user }))
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(list2, size, index, searchTerm, orderBy, month, year, user)
  yield put({
    type: actions.LIST_PROVISION,
    result,
  })
}

function* fetchProvisionEdit({ id }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(find, id)
  yield put({
    type: actions.EDIT_PROVISION,
    result,
  })
}

function* fetchProvisionSave(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(save, values)
  if (result !== null) {
    notification.success({ message: SAVE_200_MSG, description: SAVE_200_DESC })
  }
  yield put({
    type: actions.SAVE_PROVISION_RECIEVED,
    result,
  })
  yield put({
    type: actions.RE_FETCH_PROVISIONS,
  })
}

function* fetchProvisionDelete(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(remove, values)
  if (result !== null) {
    notification.success({ message: DEL_200_MSG, description: DEL_200_DESC })
  }
  yield put({
    type: actions.DELETE_PROVISION_RECIEVED,
    result,
  })
  yield put({
    type: actions.RE_FETCH_PROVISIONS,
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_PROVISIONS, fetchProvisions),
    takeEvery(actions.FETCH_PROVISION, fetchProvisionEdit),
    takeEvery(actions.SAVE_PROVISION, fetchProvisionSave),
    takeEvery(actions.DELETE_PROVISION, fetchProvisionDelete),
    takeEvery(actions.RE_FETCH_PROVISIONS, fetchProvisions),
  ])
}
