import actions from './actions'

export default function OffersReducer(state = { loading: false }, action) {
  switch (action.type) {
    case actions.REQUESTING:
      return {
        ...state,
        loading: true,
        editing: null,
        saveSuccess: false,
        com: null
      }
    case actions.LIST_OFFER:
      return {
        ...state,
        result: action.result,
        loading: false,
        saveSuccess: false,
      }
    case actions.EDIT_OFFER:
      return {
        ...state,
        editing: action.result,
        loading: false,
        saveSuccess: false,
      }
    case actions.EDIT_OFFER2_REC:
      return {
        ...state,
        com: action.result,
        loading: false,
        saveSuccess: false,
      }

    case actions.RESET_OFFER:
      return {
        ...state,
        editing: null,
        loading: false,
        saveSuccess: false,
      }
    case actions.NEW_OFFER:
      return {
        ...state,
        editing: { offerID: 0 },
        loading: false,
        saveSuccess: false,
      }
    case actions.CREATE_OFFER_RECIEVED ||
      actions.UPDATE_OFFER_RECIEVED ||
      actions.DELETE_OFFER_RECIEVED:
      return {
        ...state,
        editing: { offerID: 0 },
        loading: false,
        saveSuccess: true, // mark
      }
    case actions.RESPONSE_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        saveSuccess: false,
        message: action.message,
      }
    default:
      return state
  }
}
